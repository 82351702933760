import React, {memo, useEffect, useRef, useState} from "react";
import {useRouter} from "next/router";
import {setMetaPath} from "../../store/general";
import {useDispatch, useSelector} from "react-redux";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import {IProps} from "../../types/homePageTypes";

import AOS from "aos";
import "aos/dist/aos.css";

const BlockSlideShow = dynamic(() => import("../blocks/BlockSlideShow"))
const BlockProducts = dynamic(() => import('../blocks/BlockProducts'))
const SelectField = dynamic(() => import('../customFields/MultiSelectField'))

import {cartTranslation} from "../../store/cart";
import BuilderSection from "../builder/BuilderDataRender";


function HomePageOne(props: IProps) {

    const {domain} = props
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    const dispatch = useDispatch();

    const [newProducts, setNewProducts] = useState(props.newProducts);
    const [featuredProducts, setFeaturedProducts] = useState(props.featuredProducts);
    const [dbName, _] = useState(props.dbName);
    const customer = useSelector((state: IState) => state.customer);
    const cartToken = useSelector((state: IState) => state.cartToken);
    const locale = useSelector((state: IState) => state.locale.code)

    const router = useRouter();

    const homepage_title_text = props.homepage_title_text
    const homepage_intro_text = props.homepage_intro_text

    const prevCurrencyCodeRef = useRef(null);
    dispatch(setMetaPath(dbName));

    let componentsData = props.getBuilderData.components || {};
    let tree = props.getBuilderData.tree
    let initialLayout = props.getBuilderData.initialLayout || {}

    useEffect(() => {
        prevCurrencyCodeRef.current = props.currency;
        setNewProducts(props.newProducts);
        setFeaturedProducts(props.featuredProducts);

        // eslint-disable-next-line
    }, [router.locale, props.currency]);

    useEffect(() => {
        dispatch(cartTranslation(cartToken, customer, locale));
    }, [locale, cartToken, customer]);

    const prodsArr = [
        {
            product: "builder1",
            title: "builder1",
            type: 'builder'
        },
        {
            product: newProducts,
            title: newProducts?.[0]?.block_title,
            type: 'product'
        },
        {
            product: "builder2",
            title: "builder2",
            type: 'builder'
        },
        {
            product: featuredProducts,
            title: featuredProducts?.[0]?.block_title,
            type: 'product'
        },
        {
            product: "builder3",
            title: "builder3",
            type: 'builder'
        },
    ]

    const drawProducts = (product: any, title: string | JSX.Element, type: any) => {
        if (title === 'builder1') {
            return (
                <BuilderSection
                    tree={tree}
                    initialLayout={initialLayout}
                    componentsData={componentsData}
                    tab={1}
                    domain={domain}
                />
            )
        } else if (product.length && type === 'product') {
            return (
                <BlockProducts
                    layout="large-first"
                    customer={customer}
                    title={title}
                    dbName={dbName}
                    rate={props.rate}
                    products={product}
                    addInClass={product.length <= 4 ? "small" : "large"}
                />
            )
        } else if (title === 'builder2') {
            {
                return <BuilderSection
                    tree={tree}
                    initialLayout={initialLayout}
                    componentsData={componentsData}
                    tab={2}
                    domain={domain}
                />
            }
        } else if (title === 'builder3') {
            {
                return <BuilderSection
                    tree={tree}
                    initialLayout={initialLayout}
                    componentsData={componentsData}
                    tab={3}
                    domain={domain}
                />
            }
        }
    }

    return (
        <>
            {props.sliderData.sliders.length !== 0 && <div
                className="home_slide_container"
            >
                <BlockSlideShow sliderData={props.sliderData} dbName={dbName}/>
            </div>}
            {
                homepage_title_text || homepage_intro_text
                    ? <div className="container welcome-title">
                        <h1>{homepage_title_text}</h1>
                        <p>{homepage_intro_text}</p>
                    </div>
                    : null
            }

            {
                prodsArr.length > 0 && prodsArr?.map((item: any, ind: number) => {
                    return (
                        <React.Fragment key={ind}>
                            {drawProducts(item.product, item.title, item.type)}
                        </React.Fragment>
                    )
                })
            }
        </>
    );
}

export default memo(HomePageOne);
